import { Ref } from 'vue'

type Rule = (value: string) => boolean | string

interface Input {
  label?: string
  id: string
  value: number | string | undefined
  ref: Ref<any>
  state: boolean
  rules: Rule[]
  textarea?: boolean
  tied?: string
  append?: string
  prepend?: string
  label_buy?: string
  label_mobil?: string
  type?: string
  sup?: string
  minW?: number
  error_message?: string
}

interface InputCouple {
  label: string
  opts: Input[]
}

interface Inputs {
  simple: {
    [key: string]: Input | InputCouple
    floor: Input
    floors: Input
    comment: Input
    price: Input
    price_couple: InputCouple
    built_year: Input
    built_yearCouple: InputCouple
    floorsCouple: InputCouple
    deposit: Input
    zalog: Input
    agent_reward: Input
    floors_new_design: InputCouple
  }
  m3: {
    [key: string]: Input | InputCouple
    area: Input
    areaCouple: InputCouple
    kitchen_area: Input
    kitchen_areaCouple: InputCouple
    land_area: Input
    land_areaCouple: InputCouple
    living_area: Input
    living_areaCouple: InputCouple
  }
}

export const SELECTS = {
  house_type: [
    { label: 'Кирпичный', value: 'brick' },
    { label: 'Панельный', value: 'panel' },
    { label: 'Блочный', value: 'block' },
    { label: 'Монолитный', value: 'monolith' },
    {
      label: 'Монолитно-кирпичный',
      value: 'brick_monolith',
    },
    { label: 'Деревянный', value: 'wood' },
  ],
  rooms: [
    { label: 'Студия', value: 'studio' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10+', value: '10+' },
  ],
  renovation: [
    { label: 'Требуется', value: 'needed' },
    { value: 'redecorating', label: 'Косметический' },
    { value: 'euro', label: 'Евро' },
    { value: 'designer', label: 'Дизайнерский' },
  ],
  furniture: [
    { value: 'tv', label: 'Телевизор' },
    { value: 'sofa', label: 'Диван' },
    { value: 'bed', label: 'Кровать' },
    {
      value: 'kitchen_set',
      label: 'Кухонный гарнитур',
    },
    { value: 'kitchen_table', label: 'Стол кухонный' },
    { value: 'chairs', label: 'Стулья' },
    {
      value: 'computer_table',
      label: 'Компьютерный стол',
    },
    { value: 'refrigerator', label: 'Холодильник' },
    { value: 'armoire', label: 'Шкаф' },
    { value: 'wardrobe', label: 'Шкаф-купе' },
    {
      value: 'one_and_a_half_bed',
      label: 'Кровать-полуторка',
    },
  ],
  walls_type: [
    { label: 'Кирпич', value: 'brick' },
    { label: 'Брус', value: 'brus' },
    { label: 'Бревно', value: 'brevno' },
    { label: 'Газоблоки', value: 'gas_block' },
    { label: 'Металл', value: 'metall' },
    { label: 'Пеноблоки', value: 'foam_block' },
    { label: 'Сэндвич-панели', value: 'sandwich' },
    {
      label: 'Ж/Б панели',
      value: 'metall_concrete_panel',
    },
    {
      label: 'Эксперементальные материалы',
      value: 'experimental',
    },
  ],
  time_to_city: [
    { label: '10 мин', value: '10' },
    { label: '20 мин', value: '20' },
    { label: '30 мин', value: '30' },
    { label: '40 мин', value: '40' },
    { label: '50 мин', value: '50' },
    { label: '60 мин', value: '60' },
    { label: '90 мин', value: '90' },
    { label: '120+ мин', value: '120+' },
  ],
  time_to_metro: [
    { label: '5 мин', value: '5' },
    { label: '10 мин', value: '10' },
    { label: '15 мин', value: '15' },
    { label: '20 мин', value: '20' },
    { label: '30 мин', value: '30' },
    { label: '40+ мин', value: '40+' },
  ],
  decoration: [
    { label: 'Без отделки', value: 'none' },
    { label: 'Предчистовая', value: 'pre_finishing' },
    { label: 'Чистовая', value: 'finishing' },
    { label: 'Офисная', value: 'office' },
  ],
  additions: [
    { label: 'Беседка', value: 'gazebo' },
    { label: 'Баня', value: 'bathhouse' },
    { label: 'Гриль-зона', value: 'grill_area' },
    { label: 'Теплица', value: 'greenhouse' },
  ],
  communications: [
    { label: 'Газ', value: 'gas' },
    { label: 'Вода', value: 'water' },
    { label: 'Электричество', value: 'electricity' },
    { label: 'Отопление', value: 'heating' },
    { label: 'Канализация', value: 'sewerage' },
  ],
  keys: [
    { label: 'Да', value: true },
    { label: 'Нет', value: false },
  ],

  is_apartments: [
    { label: 'Да', value: 'apartment' },
    { label: 'Нет', value: 'non_apartment' },
  ],
  land_status: [
    { value: 'office', label: 'Офис' },
    { value: 'shopping', label: 'Торгового назначения' },
    { value: 'free_appoint', label: 'Свободная площадь' },
    { value: 'warehouse', label: 'Склад' },
    { value: 'industry', label: 'Производство' },
    { value: 'cook', label: 'Общепит' },
    { value: 'hotel', label: 'Гостиница' },
    { value: 'autoservice', label: 'Автосервис' },
    { value: 'building', label: 'Здание целиком' },
  ],
  land_status_earth: [
    { label: 'ИЖС', value: 'igs' },
    { value: 'snt', label: 'СНТ' },
  ],
  sell_purpose: [
    { label: 'Купить недвижимость', value: 'buy_flat' },
    { label: 'Получить деньги', value: 'got_money' },
    { label: 'Размен', value: 'exchange' },
    { label: 'Инвестиции', value: 'investment' },
  ],
  purchase_purpose: [
    { label: 'Жить', value: 'accommodation' },
    { label: 'Сдать', value: 'rent' },
    { label: 'Перепродать', value: 'investment' },
  ],
  possible_tenant: [
    { label: 'Всех', value: 'all' },
    { label: 'Семейную пара без детей', value: 'family_without_child' },
    { label: 'Семейную пара с детьми', value: 'family_with_child' },
    { label: 'Одного мужчину', value: 'single_man' },
    { label: 'Одну женщину', value: 'single_woman' },
    { label: 'Несколько мужчин', value: 'few_man' },
    { label: 'Несколько женщин', value: 'few_woman' },
  ],
  rental_period: [
    { label: 'Месяц', value: 'month' },
    { label: 'Пару месяцев', value: 'couple_month' },
    { label: 'Полгода', value: 'half_year' },
    { label: 'Год', value: 'year' },
    { label: 'Более года', value: 'year+' },
  ],
  is_communication_price_included: [
    { label: 'Включены', value: true },
    { label: 'Не включены', value: false },
  ],
  is_subrent: [
    { label: 'Да', value: true },
    { label: 'Нет', value: false },
  ],
  neighbours: [
    { label: 'Семейная пара', value: 'family_couple' },
    { label: 'Одна - две женщины', value: 'one_two_woman' },
    { label: 'Один - двое мужчин', value: 'one_two_man' },
    { label: 'Хозяева', value: 'owner' },
  ],
  garage_facilities: [
    { label: 'Охрана', value: 'security' },
    { label: 'Смотровая яма', value: 'inspection_hole' },
  ],
  garage_type: [
    { label: 'Металический', value: 'metall' },
    { label: 'Кирпичный', value: 'brick' },
    { label: 'Бетонный', value: 'metall_concrete' },
  ],
  car_place_type: [
    { label: 'Многоуровневая парковка', value: 'multilevel' },
    { label: 'Подземная', value: 'underground' },
    { label: 'Крытая', value: 'covered' },
    { label: 'Открытая', value: 'open' },
  ],
  floors: [
    { label: 'Не первый', value: 'not_first' },
    { label: 'Не последний', value: 'not_last' },
  ],
}

const parseNoFloat = (value: string | number | undefined | null) => {
  if (!value) return 0
  return parseInt(value.toString().replace(/\s/g, ''), 10)
}
const generateRules = (args: {
  less: number | undefined
  more: number | undefined
  type: string
  prop: string
  from?: string
  to?: string
  isCouple?: boolean
}): Rule[] => {
  let rulesMap: { [key: string]: Rule } = {
    more: (value: string) =>
      !value ||
      parseNoFloat(value) >= args.more! ||
      createErrorMessage(
        `Должно быть больше ${args.more!.toLocaleString()}`,
        INPUTS[args.type][args.prop],
      ),
    less: (value: string) =>
      parseNoFloat(value) <= args.less! ||
      createErrorMessage(
        `Должно быть меньше ${args.less!.toLocaleString()}`,
        INPUTS[args.type][args.prop],
      ),
  }
  if (args.to && args.from) {
    if (!args.isCouple) {
      rulesMap = {
        ...rulesMap,
        ...{
          from: (value: string) =>
            !INPUTS[args.type][args.to].value ||
            parseNoFloat(value) <= parseNoFloat(INPUTS[args.type][args.to].value) ||
            createErrorMessage('"от" должно быть меньше "до"', INPUTS[args.type][args.from]),
          to: (value: string) =>
            !INPUTS[args.type][args.to].value ||
            !INPUTS[args.type][args.from].value ||
            parseNoFloat(value) >= parseNoFloat(INPUTS[args.type][args.from].value) ||
            createErrorMessage('"до" должно быть больше "от"', INPUTS[args.type][args.to]),
        },
      }
    } else {
      rulesMap = {
        ...rulesMap,
        ...{
          from: (value: string) =>
            !INPUTS[args.type][args.to].opts[1].value ||
            parseNoFloat(value) <= parseNoFloat(INPUTS[args.type][args.to].opts[1].value) ||
            createErrorMessage(
              '"от" должно быть меньше "до"',
              INPUTS[args.type][args.from].opts[0],
            ),
          to: (value: string) =>
            !INPUTS[args.type][args.to].opts[1].value ||
            !INPUTS[args.type][args.from].opts[0].value ||
            parseNoFloat(value) >= parseNoFloat(INPUTS[args.type][args.from].opts[0].value) ||
            createErrorMessage('"до" должно быть больше "от"', INPUTS[args.type][args.to].opts[1]),
        },
      }
    }
  }

  const selectedRules: Object[] = Object.keys(args).filter(
    (key) => args[key] !== null && rulesMap[key] !== null,
  )

  return selectedRules.map((key) => rulesMap[key])
}

const createErrorMessage = (
  error_message: string,
  value_pointer: { error_message: string; opts?: [] } | undefined | any,
) => {
  if (!value_pointer) {
    value_pointer.error_message = 'Ошибка'
    return ''
  }
  if (value_pointer.opts) {
    value_pointer.opts.forEach((item: { error_message: string }) => {
      item.error_message = error_message
    })
  } else {
    value_pointer.error_message = error_message
  }
  return ''
}

const INPUTS_RULES = {
  m3: {
    area: (value: string) =>
      !INPUTS.m3.area.value ||
      parseNoFloat(value) >=
        parseNoFloat(INPUTS.m3.kitchen_area.value) + parseNoFloat(INPUTS.m3.living_area.value) ||
      createErrorMessage('Общая площадь должна быть больше кухни + жилой.', INPUTS.m3.area),
    areaCouple: {
      area_from: null,
      area_to: (value: number) =>
        !INPUTS.m3.areaCouple.opts[1].value ||
        value >=
          parseNoFloat(INPUTS.m3.kitchen_areaCouple.opts[1].value) +
            parseNoFloat(INPUTS.m3.living_areaCouple.opts[1].value) ||
        createErrorMessage(
          '"до" площади должен быть больше все остальных "до"',
          INPUTS.m3.areaCouple.opts[1],
        ),
    },
    kitchen_area: (value: string) =>
      !INPUTS.m3.area.value ||
      !INPUTS.m3.kitchen_area.value ||
      parseNoFloat(INPUTS.m3.area.value) >=
        parseNoFloat(value) + parseNoFloat(INPUTS.m3.living_area.value) ||
      createErrorMessage('Кухня + жилая должна быть меньше общей площади.', INPUTS.m3.kitchen_area),
    kitchen_areaCouple: {
      kitchen_area_from: null,
      kitchen_area_to: (value: number) =>
        !INPUTS.m3.areaCouple.opts[1].value ||
        value <=
          parseNoFloat(INPUTS.m3.areaCouple.opts[1].value) +
            parseNoFloat(INPUTS.m3.living_areaCouple.opts[1].value) ||
        createErrorMessage(
          '"до" площади должен быть больше все остальных "до"',
          INPUTS.m3.kitchen_areaCouple.opts[1],
        ),
    },
    living_area: (value: string) =>
      !INPUTS.m3.area.value ||
      !INPUTS.m3.living_area.value ||
      parseNoFloat(INPUTS.m3.area.value) >=
        parseNoFloat(value) + parseNoFloat(INPUTS.m3.kitchen_area.value) ||
      createErrorMessage('Кухня + жилая должна быть меньше общей площади.', INPUTS.m3.living_area),
    living_areaCouple: {
      living_area_from: null,
      living_area_to: (value: string) =>
        !INPUTS.m3.areaCouple.opts[1].value ||
        parseNoFloat(value) <=
          parseNoFloat(INPUTS.m3.areaCouple.opts[1].value) +
            parseNoFloat(INPUTS.m3.kitchen_areaCouple.opts[1].value) ||
        createErrorMessage(
          '"до" площади должен быть больше все остальных "до"',
          INPUTS.m3.living_areaCouple.opts[1],
        ),
    },
  },
}

export const INPUTS: Inputs = {
  simple: {
    floor: {
      label: 'Этаж',
      id: 'floor',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [],
      tied: 'floors',
      error_message: '',
    },
    floors: {
      label: 'Этажей в доме',
      id: 'floors',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [],
      tied: 'floor',
      error_message: '',
    },
    floors_in_house: {
      label: 'Этажей в доме',
      id: 'floors',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [],
      tied: 'floor',
      error_message: '',
    },

    comment: {
      label: 'Комментарий',
      id: 'comment',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [(value: any) => !value || value.length <= 300 || ''],
      textarea: true,
      error_message: '',
    },
    price: {
      label: 'Цена',
      id: 'price',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [],
      append: ' ₽',
      error_message: '',
    },
    price_couple: {
      label: 'Цена',
      opts: [
        {
          id: 'price_from',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'price_to',
          append: ' ₽',
          error_message: '',
        },
        {
          id: 'price_to',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'price_from',
          append: ' ₽',
          error_message: '',
        },
      ],
    },
    built_year: {
      label: 'Год постройки',
      id: 'built_year',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [],
      append: ' г',
      error_message: '',
    },
    built_yearCouple: {
      label: 'Год постройки',
      opts: [
        {
          id: 'built_year_from',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          append: ' г',
          tied: 'built_year_to',
          error_message: '',
        },
        {
          id: 'built_year_to',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          append: ' г',
          tied: 'built_year_from',
          error_message: '',
        },
      ],
    },
    floors_new_design: {
      label: 'Этаж',
      opts: [
        {
          id: 'floor',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          sup: 'из',
          minW: 60,
          tied: 'floors',
          error_message: '',
        },
        {
          id: 'floors',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          minW: 60,
          tied: 'floor',
          error_message: '',
        },
      ],
    },
    floorsCouple: {
      label: 'Этажей в доме',
      opts: [
        {
          id: 'floors_from',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'floors_to',
          error_message: '',
        },
        {
          id: 'floors_to',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'floors_from',
          error_message: '',
        },
      ],
    },
    floorCouple: {
      label: 'Этаж',
      opts: [
        {
          id: 'floor_from',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'floor_to',
          error_message: '',
        },
        {
          id: 'floor_to',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'floor_from',
          error_message: '',
        },
      ],
    },
    deposit: {
      label: 'Депозит',
      id: 'deposit',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [],
      append: ' ₽',
      error_message: '',
    },
    deposit_couple: {
      label: 'Депозит',
      opts: [
        {
          id: 'deposit_from',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'deposit_to',
          append: ' ₽',
          error_message: '',
        },
        {
          id: 'deposit_to',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'deposit_from',
          append: ' ₽',
          error_message: '',
        },
      ],
    },
    zalog: {
      label: 'Залог',
      id: 'zalog',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [],
      append: ' ₽',
      error_message: '',
    },
    zalog_couple: {
      label: 'Залог',
      opts: [
        {
          id: 'zalog_from',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'zalog_to',
          append: ' ₽',
          error_message: '',
        },
        {
          id: 'zalog_to',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'zalog_from',
          append: ' ₽',
          error_message: '',
        },
      ],
    },
    agent_reward: {
      label: 'Агентские',
      id: 'agent_reward',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [],
      append: ' ₽',
      error_message: '',
    },
  },
  m3: {
    area: {
      label: 'Площадь',
      label_buy: 'общая',
      label_mobil: 'Площадь',
      id: 'area',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [INPUTS_RULES.m3.area],
      append: 'м²',
      error_message: '',
    },
    areaCouple: {
      label: 'Площадь',
      opts: [
        {
          id: 'area_from',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'area_to',
          append: 'м²',
        },
        {
          id: 'area_to',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [INPUTS_RULES.m3.areaCouple.area_to],
          tied: 'area_from',
          append: 'м²',
          error_message: '',
        },
      ],
    },
    kitchen_area: {
      label: 'Кухня',
      label_buy: 'кухня',
      id: 'kitchen_area',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [INPUTS_RULES.m3.kitchen_area],
      append: 'м²',
      error_message: '',
    },
    kitchen_areaCouple: {
      label: 'Кухня',
      opts: [
        {
          id: 'kitchen_area_from',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'kitchen_area_to',
          append: 'м²',
        },
        {
          id: 'kitchen_area_to',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [INPUTS_RULES.m3.kitchen_areaCouple.kitchen_area_to],
          tied: 'kitchen_area_from',
          append: 'м²',
          error_message: '',
        },
      ],
    },
    land_area: {
      label: 'Площадь',
      // label_buy: 'участок',
      id: 'land_area',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [],
      append: 'сот',
      error_message: '',
    },
    land_areaCouple: {
      label: 'Площадь',
      opts: [
        {
          id: 'land_area_from',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'land_area_to',
          append: 'сот',
          error_message: '',
        },
        {
          id: 'land_area_to',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'land_area_from',
          append: 'сот',
          error_message: '',
        },
      ],
    },
    living_area: {
      label: 'Жилая',
      label_buy: 'жилая',
      id: 'living_area',
      value: undefined,
      ref: ref(),
      state: false,
      rules: [INPUTS_RULES.m3.living_area],
      append: 'м²',
      error_message: '',
    },
    living_areaCouple: {
      label: 'Жилая',
      opts: [
        {
          id: 'living_area_from',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [],
          tied: 'living_area_to',
          append: 'м²',
          error_message: '',
        },
        {
          id: 'living_area_to',
          value: undefined,
          ref: ref(),
          state: false,
          rules: [INPUTS_RULES.m3.living_areaCouple.living_area_to],
          tied: 'living_area_from',
          append: 'м²',
          error_message: '',
        },
      ],
    },
  },
}

const createInputRules = () => {
  const generateSimpleRules = (args: {
    prop: string
    more: number
    less: number
    from?: string
    to?: string
    isCouple?: boolean
    item?: Input
    type: 'simple' | 'm3'
  }) => {
    const input = args.item ?? INPUTS[args.type][args.prop]

    if (input && input.rules) {
      input.rules = [
        ...input.rules,
        ...generateRules({
          less: args.less,
          more: args.more,
          from: args.from,
          to: args.to,
          isCouple: args.isCouple,
          type: args.type,
          prop: args.prop,
        }),
      ]
    }
  }

  generateSimpleRules({
    prop: 'floor',
    more: 1,
    less: 100,
    from: 'floor',
    to: 'floors',
    type: 'simple',
  })
  generateSimpleRules({
    prop: 'floors',
    more: 1,
    less: 100,
    from: 'floor',
    to: 'floors',
    type: 'simple',
  })
  generateSimpleRules({ prop: 'price', more: 1000, less: 10000000000, type: 'simple' })
  generateSimpleRules({ prop: 'floors_in_house', more: 1, less: 20, type: 'simple' })
  generateSimpleRules({ prop: 'built_year', more: 1900, less: 2050, type: 'simple' })
  generateSimpleRules({ prop: 'deposit', more: 1, less: 1000000, type: 'simple' })
  generateSimpleRules({ prop: 'zalog', more: 1, less: 1000000, type: 'simple' })
  generateSimpleRules({ prop: 'agent_reward', more: 1, less: 1000000, type: 'simple' })
  generateSimpleRules({ prop: 'area', more: 10, less: 1000, type: 'm3' })
  generateSimpleRules({ prop: 'kitchen_area', more: 1, less: 100, type: 'm3' })
  generateSimpleRules({ prop: 'land_area', more: 1, less: 10000, type: 'm3' })
  generateSimpleRules({ prop: 'living_area', more: 5, less: 1000, type: 'm3' })

  INPUTS.simple.price_couple.opts.forEach((item) =>
    generateSimpleRules({
      prop: 'price_couple',
      more: 1000,
      less: 10000000000,
      from: 'price_couple',
      to: 'price_couple',
      isCouple: true,
      item: item,
      type: 'simple',
    }),
  )
  INPUTS.simple.built_yearCouple.opts.forEach((item) =>
    generateSimpleRules({
      prop: 'built_yearCouple',
      more: 1900,
      less: 2050,
      from: 'built_yearCouple',
      to: 'built_yearCouple',
      isCouple: true,
      item: item,
      type: 'simple',
    }),
  )
  INPUTS.simple.floorsCouple.opts.forEach((item) =>
    generateSimpleRules({
      prop: 'floorsCouple',
      more: 1,
      less: 100,
      from: 'floorsCouple',
      to: 'floorsCouple',
      isCouple: true,
      item: item,
      type: 'simple',
    }),
  )
  INPUTS.simple.floorCouple.opts.forEach((item) =>
    generateSimpleRules({
      prop: 'floorCouple',
      more: -1,
      less: 100,
      from: 'floorCouple',
      to: 'floorCouple',
      isCouple: true,
      item: item,
      type: 'simple',
    }),
  )

  INPUTS.m3.areaCouple.opts.forEach((item) =>
    generateSimpleRules({
      prop: 'areaCouple',
      more: 10,
      less: 1000,
      from: 'areaCouple',
      to: 'areaCouple',
      isCouple: true,
      item: item,
      type: 'm3',
    }),
  )

  INPUTS.m3.kitchen_areaCouple.opts.forEach((item) =>
    generateSimpleRules({
      prop: 'kitchen_areaCouple',
      more: 1,
      less: 100,
      from: 'kitchen_areaCouple',
      to: 'kitchen_areaCouple',
      isCouple: true,
      item: item,
      type: 'm3',
    }),
  )
  INPUTS.m3.land_areaCouple.opts.forEach((item) =>
    generateSimpleRules({
      prop: 'land_areaCouple',
      more: 1,
      less: 10000,
      from: 'land_areaCouple',
      to: 'land_areaCouple',
      isCouple: true,
      item: item,
      type: 'm3',
    }),
  )
  INPUTS.m3.living_areaCouple.opts.forEach((item) =>
    generateSimpleRules({
      prop: 'living_areaCouple',
      more: 5,
      less: 1000,
      from: 'living_areaCouple',
      to: 'living_areaCouple',
      isCouple: true,
      item: item,
      type: 'm3',
    }),
  )
}

createInputRules()

export const MAJOR_TYPES = [
  { object_type: 'flat', label: 'Квартира' },
  { object_type: 'room', label: 'Комната' },
  { object_type: 'house', noSubObj: false, label: 'Дом/Дача/Коттедж/Таунхаус' },
  { object_type: 'earth', noSubObj: true, label: 'Земельный участок' },
  { object_type: 'commercial', noSubObj: true, label: 'Коммерческая' },
  { object_type: 'garage', noSubObj: false, label: 'Гараж/Машино-место' },
]
